.about_container {
    height: max-content;
    width: inherit;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    padding: 3% 2%;
}

.about_content {
    display: flex;
    flex-direction: row;
    flex: 1 1 auto;
}

.about_text_container {
    flex: 50 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.about_title {
    font-family: "Montserrat", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
    width: inherit;
    text-align: left;
    color: #42446E;
    padding: 5%;
}

.about_name {
    color: #13B0F5;
}

@keyframes wave {
    0% {
        transform: rotate(0deg);
    }

    10% {
        transform: rotate(-10deg);
    }

    20% {
        transform: rotate(12deg);
    }

    30% {
        transform: rotate(-10deg);
    }

    40% {
        transform: rotate(9deg);
    }

    50% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(0deg);
    }
}

.wave_emoji_container {
    display: inline-block;
    transform-origin: center;
    animation: wave 2s infinite;
}

.wave_emoji {
    height: 1em;
    width: 1em;
    margin: 0px 0.05em 0px 0.3em;
    vertical-align: -0.1em;
}

.about_text {
    font-family: 'Roboto', sans-serif;
    font-size: 1.3em;
    font-weight: 700;
    color: #42446E;
    padding: 5%;
}

.about_image {
    width: auto;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 50;
}