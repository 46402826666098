html {
    scroll-behavior: smooth;
}

.portfolio-page_container {
    height: auto;
    width: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #f5f5f5;
    border: 5px solid #64ffda;
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

.section_heading {
    width: inherit;
    height: auto;
    padding: 1rem;
    margin: 0;
    font-family: "Montserrat", sans-serif;
    font-optical-sizing: auto;
    font-style: normal;
    font-size: 2rem;
    font-weight: 600;
    text-align: left;
    color: #000;
}