.experience_container {
    width: inherit;
    height: auto;
    display: flex;
    flex-direction: column;
    text-align: left;
    box-sizing: border-box;
    padding: 3% 2%;
}

.card_header_top {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.company_card_containers {
    width: auto;
    height: auto;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    grid-auto-rows: auto;
    grid-gap: 2%;
    padding: 4% 0;
}