.contact_container {
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
    text-align: center;
}
.contact_content {
    margin-top: 20px;
}

.contact_email {
    font-size: 1.2rem;
    margin-bottom: 20px;
}

.contact_links {
    display: flex;
    justify-content: center;
    align-items: center;
}

.contact_links a {
    font-size: 24px;
    color: #555;
    margin: 0 10px;
    transition: color 0.3s ease-in-out;
}

.contact_links a:hover {
    color: #007bff;
}

.contact_links a:focus {
    outline: none;
}