:root {
    --darkmode-toggle-size: 3rem;
    --background-color-light: #fff;
    --background-color-dark: #282c35;
    --color-text: #060606;
    --color-fore-1: #f5f5f5;
    --color-fore-2: #03adfc;
    --color-fore-3: #c9c9c9;
}

.darkmode-toggle-wrapper {
    --darkmode-toggle-height: var(--darkmode-toggle-size);
    --darkmode-toggle-width: calc(var(--darkmode-toggle-height) * 2);
    position: relative;
    background: var(--color-fore-1);
    width: var(--darkmode-toggle-width);
    height: var(--darkmode-toggle-height);
    border-radius: calc(var(--darkmode-toggle-height) / 2);
    display: flex;
    align-items: center;
    justify-content: space-around;
    border: 1px solid var(--color-fore-3);
}

.darkmode-switch {
    background: #364059;
}

.darkmode-toggle-checkbox {
    opacity: 0;
    height: var(--darkmode-toggle-height);
    width: var(--darkmode-toggle-width);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    cursor: pointer;
}

.darkmode-toggle-circle {
    position: absolute;
    top: 0;
    left: 0;
    right: calc(var(--darkmode-toggle-width) / 2);
    height: var(--darkmode-toggle-height);
    width: calc(var(--darkmode-toggle-width) / 2);
    border-radius: 50%;
    background: var(--background-color-light);
    border: 2px solid var(--color-fore-2);
    box-sizing: border-box;
    box-shadow: 0 0 calc(var(--darkmode-toggle-width) / 10) var(--color-fore-2);
    transition: 0.1s ease-in;
}

.darkmode-toggle-checkbox:checked+.darkmode-toggle-circle {
    left: calc(var(--darkmode-toggle-width) / 2);
    right: 0;
    background: var(--background-color-dark);
    border: 2px solid var(--color-text);
    box-shadow: 0 0 calc(var(--darkmode-toggle-width) / 10) var(--color-text);
}

.darkmode-toggle-emoji {
    font-size: calc(var(--darkmode-toggle-height) * 0.6);
    line-height: 0;
}