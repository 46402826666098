.skills_container {
    width: inherit;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 3% 2%;
}

.skills_grid {
    width: inherit;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 20px;
    grid-auto-flow: dense;
}

.skills_card {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
}

.skills_card:hover {
    transform: translateY(-5px);
}

.skills_card h3 {
    margin-bottom: 10px;
}

.skills_list_item_ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.skills_list_item_ul li {
    font-size: 1rem;
}