.company_card {
    height: max-content;
    width: auto;
    display: flex;
    flex-direction: column;
    margin: 2%;
    padding: 2% 3% 3% 3%;
    border-radius: 10px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
    background-color: #fff;
    transition: 0.3s;
    aspect-ratio: 1;
}

.company_card:hover {
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.4);
}

.company_card_index {
    font-size: 1.2rem;
    font-weight: 600;
    color: #000;
    margin: 0;
}

.company_card_header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 2% 0;
}

.card_header_left {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    flex: 60;
    flex-wrap: wrap;
}

.card_header_left_name {
    font-size: 1.1rem;
    font-weight: 600;
    color: #000;
}

.card_header_left_position {
    font-size: 0.9rem;
    font-weight: 400;
    color: #000;
}

.card_header_right {
    display: flex;
    flex-direction: column;
    flex: 40;
    justify-content: space-between;
    align-items: flex-end;
    text-align: right;
}

.card_content {
    padding: 1% 2%;
}