.navbar_container {
    width: inherit;
    height: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 1%;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
}

.navbar_logo {
    flex: 30;
    font-size: 1.5rem;
    font-weight: 600;
    text-decoration: none;
    color: #000;
}

.navbar_logo-arrow {
    font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
    font-size: 1.5rem;
    color: #868e96;
}

.navbar_logo-name {
    font-family: "Cookie", cursive;
    font-weight: 700;
    font-style: normal;
    font-size: 2rem;
    padding: 0 10px;
    color: #551A8B;
}

.navbar_menu {
    flex: 70;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.navbar_menu_items {
    display: flex;
    flex: 1;
    justify-content: space-evenly;
    align-items: center;
    list-style: none;
}

.navbar_menu_items a {
    font-family: "Itim", cursive;
    font-weight: 800;
    font-size: 1.2rem;
    text-decoration: none;
}

.navbar_menu_toggle {
    height: auto;
    width: auto;
}