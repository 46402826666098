.education_container {
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
    text-align: center;
    padding: 3% 2%;
}

.education_content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.education_item {
    width: 100%;
    max-width: 600px;
    padding: 20px;
    margin: 20px;
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
}

.education_university_name {
    font-size: 1.2rem;
    font-weight: bold;
    margin-bottom: 10px;
}

.education_degree,
.education_graduation_date,
.education_cgpa {
    font-size: 1rem;
    margin-bottom: 5px;
}