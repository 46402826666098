.image_card-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1em;
}

.image_card {
    max-width: 300px;
    width: 100%;
    height: auto;
    border-radius: 10px;
    border: 2px solid #13B0F5;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease-in-out;
}

.image_card:hover {
    transform: translateY(-5px);
}

.image_card img {
    width: 100%;
    height: auto;
    border-radius: 10px;
}